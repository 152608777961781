import { vacanciesQuery } from '~/statamic/queries/vacancy';
import type { Vacancies } from '~/statamic/types/vacancy';

type VacanciesOptions = {
  sort?: string;
  site?: string;
  page?: number;
  limit?: number;
  filter?: JSON;
  collection?: string;
};

export const useStatamicVacancies = async (options: VacanciesOptions = {}): Promise<Vacancies> => {
  const { $statamic } = useNuxtApp();

  if (!options.site) {
    options.site = $statamic.site.handle;
  }

  const { data } = await useAsyncQuery<{vacancies: Vacancies}>(vacanciesQuery, {
    ...options,
    collection: 'vacancies',
  });
  const vacancies = data?.value?.entries;

  if (!vacancies) {
    throw new Error('Missing vacancies from Statamic.');
  }

  return vacancies;
};
