export const vacanciesQuery = gql`
  query getEntries(
    $sort: String = "date desc",
    $site: String!,
    $page: Int! = 0,
    $limit: Int! = 999,
    $filter: JsonArgument,
    $collection: [String],
  ) {
    entries(
      sort: [$sort],
      site: $site,
      page: $page,
      limit: $limit,
      filter: $filter,
      collection: $collection,
    ) {
      to
      from
      total
      last_page
      current_page
      has_more_pages

      data {
        ... on Entry_Vacancies_Vacancy {
          id
          url
          title
          text
          image {
            id
            md: responsiveAsset(width: 960, height: 422, format: "webp") { url, alt, width, height }
          }
          vacancy_categories {
            id
            slug
            title
          }
          hours_per_week
          location
        }
      }
    }
  }
`;
